import React from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Carousel } from 'react-responsive-carousel';

let reactStart = (imageUrls = ["assets/1.jpeg"]) => {

    class DemoCarousel extends React.Component{
        constructor(props){
            super(props);
            this.imgRefs = [];
            this.zIndices = {};
            this.imageUrls = [];
            this.imagesHandled = {};
        }

        handleImageLoaded(){
            let images = document.querySelectorAll('div > img');
            for(let image of images){
                if(window.screen.height > image.height){
                    const aspectRatio = image.width / image.height;
                    image.style.height = "100%";
                    image.style.width = `${image.height * aspectRatio}px`;
    
                    
                        
                } if (window.screen.width > image.width){
                    const aspectRatio = image.width / image.height;
                    image.style.width = "100%";
                    image.style.height = `${image.width / aspectRatio}px`;
    
                }

                image.style.left ="50%";
                image.style.transform = "translateX(-50%)";
                if(image.src === this.imageUrls[0]){
                    image.style.zIndex = `10`;
                }

                
            }
        }

        bringToTop(imageUrl){
            let images = document.querySelectorAll('div > img');
            for(let image of images){
                if(!image || !image.style) continue;
                if(imageUrl === image.src){
                    image.style.zIndex = `10`;
                } else if (this.imageUrls){
                    image.style.zIndex = `0`;
                }
            }
        }

        render(){
            const interval = parseInt(new URLSearchParams(window.location.search).get('interval')) || 5000;
            let content = [];
            for(let idx in imageUrls){
                let imageUrl = imageUrls[idx];
                this.imageUrls[idx] = imageUrl;
                
                const i = idx;
                content.push(<div key={idx} >
                    <img src={imageUrl} className="center-cropped" ref={(img => {this.imgRefs[i] = img;})} onLoad={this.handleImageLoaded.bind(this)} />
                </div>)
            }
    
              return (
                  <Carousel className="test" onChange={(i) => {
                    this.bringToTop.bind(this)(this.imageUrls[i]);
                  }} showIndicators={false} showArrows={false} showThumbs ={false} autoPlay={true} infiniteLoop={true} swipeable={false} interval={interval}>
                      {content}
                      {/* <div>
                          <img src="https://i.dailymail.co.uk/i/pix/scaled/2015/03/09/0C07226D00000578-0-image-a-23_1425939890281.jpg" /> 
                      </div> */}
                  </Carousel>
              );
        }
    }
    
    ReactDOM.render(<DemoCarousel />, document.getElementById('root'));
    
    // ReactDOM.render(<App />, document.getElementById('root'));
    
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
    
};

window.onload = function() {
    //let regex = new RegExp("(?<=eng\\/)(.*)(?=\\?AWS)");
    // window.alert("https://s3.amazonaws.com/video-source.bellwether.link/eng/7675f309-39b5-4472-b920-99984c8e1bc9?AWSAccessKeyId=ASIA2KJF3F67CFSKN5OP&Expires=1578935573&Signature=r2AxD07F%2FQPZ%2BbQXNlt5imuxOwA%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEMH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMSJHMEUCIQD7EKCfLOH0roiG%2FQ17vihrYqMJOI4oC1UmccgvZ%2FsP3QIgHqdm%2Bw97SKlSqUhpTowO%2FcNesrVo8jll94d%2BNVENKkcqtAMIShABGgw3MDkyODU4NTEwNzAiDOuwfdLeiOlAsDuBxSqRAxeLctNoChAcR4IULI2uVgEnqS2TzJcvhQZo%2FoxwQeDUXrG%2FUXGOqNC%2FE2i2bxz3%2Blu5XN9CxjUw0LXxueuvjipbJj6ssFT7RUF0ltnCd75p8kTNjTxO31rwCRaF8N1DWCvTyj4qCVXHBZyUslMKhs48s8YBD6WdLKQeNdvRgX%2BV2QcXjPHMCsNIvsW2P%2F41M39wMDV40m%2BKPf17BPzzv4IlGNkIaduge%2BJSHNaoz23RQEkO6wkb1bpdqLJGlnvxJWXohB0EiafUHuY%2FdY%2B4cQzEmMl%2BPcNN1vOzPeB99WBqgP2oh17GwBtwyFBBz3E6jyT6qyFMFX%2Bh0ZDeWu7GrIw%2FiOaLzCwZZDsFdFKH6hU6ngimLZcQhDjWfkFmtD5zBNNRPYSW5A%2FDTIzHwug%2FoOkw9oGODyafasmiKgsYGBYmOCt2uQXcZIW5djwO9bLpdhYjsbddJT05LNW8yYPvWGA9SZX41d6%2Bn1P9ObsGuo0a8XIhiplUmPg4int8P%2BGXRS5j%2BF0BBwVg38HEfjDG%2Bz86MIXF8vAFOusBOL%2F%2FL9FK3NVbx%2F4zh3reOaFElvlZhSH1%2BoUCSpPBnV79Dk7BDw39AMs0XQaEr9JTrZhML2kphVOgT%2B4K4QBfLBl9aB3k856Wj1GE7d7FRS0yHi0lwWASZj5sDNE%2FoG8%2BrOZND8VHpA5QG%2BGdHmDy3JWVScAjftaKtRFoSh%2BQUfavSoD2p0xaTtHZ%2FYg0sBMZys%2FoK0788cFQV6fee97MSarrgNuzvFRVC0%2BGi%2FdrrxwFE0RAf8oa7EzQC%2B69aicrquRlHoxkY5VTVzo9cZnIPz0mqVShPoEululOe9uYSFjNLwnMuk6lzyJRfA%3D%3D"
    //     .match("eng\\/(.*)\\?AWS")[1]);
    const beanId = new URLSearchParams(window.location.search).get('beanId');
    
    $.ajax({
        url: `https://prod.bellwether.link/api/beans/images/${beanId}`,
        type: 'GET',
        data: {},
        beforeSend: function (xhr){ 
            xhr.setRequestHeader('Authorization', "1swswihc03t0z3a9wgr3lc"); 
        },
        success: function (res) { 
            reactStart(res);
        },
        error: function (e) { reactStart(); }
    });


    
}
